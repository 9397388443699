import { mq, variables } from '../../../cssInJs';

export default {
    dash_board_banner : mq({
        backgroundImage: 'url("/sites/anfc/media/misc/native-food-in-jars.jpg")',
        height: ['92px', null, '207px'],
    }),
    dash_board_menus : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dash_board_menu : {
        //margin: '0.5rem',
        padding: '0.5rem',
        flex : '1 0 33.3%',
        maxWidth: '33.3%',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '1.2rem',
        '&.selected' : {
          backgroundColor: '#464934',
          color : 'white'
        },
        '.dash_board_menu_item' : {
            cursor: 'pointer'
        },
        'svg' : {
            marginBottom: '5px'
        }
    },

    order_list : {
        display: 'flex',
        flexDirection: 'row',
        flexWrap : 'wrap'
    },
    order_list_item : {
        padding : '0.5rem 0rem',
        flex : '1 0 100%',
        maxWidth: '100%',
        '.order_list_item_container' : {
            //cursor: 'pointer',
            //backgroundColor: 'white',
            //padding : '1rem',
            display : 'flex',
            //flexDirection: 'column',
            flexWrap : 'wrap',
            'div' : {
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                flex : '1 0 33.3%',
                maxWidth : '33.3%'
            }
        },
        'label' : {
            marginBottom : '0rem'
        },
        '.order_list_item_order_no' : {
            //cursor: 'pointer',
            fontWeight : 700,
            transition: 'all .2s ease-in-out', 
            'a:hover' : {
                //fontWeight : 700
                color: variables.colorLightGreen
            }
        }
    },

    h_3 : {
        fontFamily: variables.familyBase
    }
}