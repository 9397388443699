//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from 'react-slick';

export function PageTilesSmall(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, 'UseSlider') || 'default';

  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
      useSlider={useSlider}
    ></Tile>
  ));

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles__small',
    useSlider,
    item.cssClass || item.anchorName || ''
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: pages.length >= 5 ? 5 : pages.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: pages.length >= 3 ? 3 : pages.length,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles__small}
    >
      {useSlider && useSlider === 'slider' ? (
        <Slider {...settings}>{pageTileElts}</Slider>
      ) : (
        pageTileElts
      )}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  //const colorTheme = page.colorTheme;
  //const index = props.idx;

  return (
    <div
      css={style.pageTile__small}
      className={`pageTile__small ${props.useSlider}`}
    >
      <SiteLink
        className="pageTile__small__content"
        css={style.pageTile__small__content}
        to={disableLink ? '' : page.pageUrl}
      >
        <div
          css={style.pageTile__small__wrapper}
          className="pageTile__small__wrapper"
        >
          <div style={{ overflow: 'hidden' }}>
            <div
              className="pageTile__small__bg"
              css={style.pageTile__small__bg}
              style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
            ></div>
          </div>

          <div
            className="pageTile__small__title"
            css={style.pageTile__small__title}
          >
            {page.pageTitle}
          </div>

          <div
            className="pageTile__small__des"
            css={style.pageTile__small__des}
          >
            {page.tileDesc}
          </div>

          <div
            css={style.pageTile__small__button}
            className="pageTile__small__button"
          >
            <button className="btn btn-primary">SHOP NOW</button>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
