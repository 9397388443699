/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useState } from "react";
import utils from "utils";
import style from "./wholesaleRegisterStyle";
import { getWholesaleRegisterModel } from "./wholesaleRegisterModel";
import { useForm } from "../../../../form";
import { Button, ErrorMessage, useRouter } from "../../../../components";
//import env from "../../../../env";
import { useAuth } from 'auth';
import { useStore } from '../../../../store';
//import { validators } from "../../../../lib/form";

export function WholesaleRegister(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "member_login",
    item.cssClass || item.anchorName || ""
  );

  const model = getWholesaleRegisterModel({});
  const form = useForm(model, {
    usePlaceholder: false,
    onControlChanged: orderItemChanged,
  });
  //const post = usePost();
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const [error, setError] = useState("");
  const val = form.getValue();
  const billingInfo = form.model.find("billingInfo");
  const shippingInfo = form.model.find("shippingInfo");
  const auth = useAuth();
  const router = useRouter();
  const { dispatch } = useStore();

  if (shippingInfo) {
    shippingInfo.disableValidators = val.useSameAddress;
  }
  billingInfo.disableValidators = false;
  
  function orderItemChanged(e, control) {}

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    form.validateForm(e, () => {
      setSendingStatus("pending");

      const value = { ...form.getValue() };
      auth.signupForWholeSale(value).then(user => {
        
        if(user && user.firstName){
          dispatch({type: 'SET_STATE', payload: { userFirstName : user.firstName }});
        }
        
        setSendingStatus(null);
        setError('');
        router.push('/wholesale-dashboard')
      }).catch(error => {
        setError(error.message);
        setSendingStatus(null);
      });

    });
  }

  //const data = post.response;

  if (cmsOption.isCmsEdit) {
    return (
      <div
        className={cssClass}
        css={style.member_login}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> Wholesale Register </b>
        </pre>
      </div>
    );
  }

  // if (post.done() && sendingStatus === "pending"){
  //   setSendingStatus(null);
  // }

  return (
    <div
      className={cssClass}
      css={style.member_login}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <form className="wholesale_register_form" css={style.wholesale_register_form}>
        <div className="form-row">
          <div className="col-md-3 mb-2">{render("firstName")}</div>
          <div className="col-md-3 mb-2">{render("lastName")}</div>
          <div className="col-md-3 mb-2">{render("email")}</div>
          <div className="col-md-3 mb-2">{render("password")}</div>
        </div>

        <div className="form-row">
          <div className="col-md-6 mb-2">{render("businessPhone")}</div>
          <div className="col-md-6 mb-2">{render("mobilePhone")}</div>
        </div>

        <h3 css={style.h_3}>Business Details</h3>
        <hr />

        <div className="form-row">
          <div className="col-md-4 mb-2">{render("businessName")}</div>
          <div className="col-md-4 mb-2">{render("abn")}</div>
          <div className="col-md-4 mb-2">{render("acn")}</div>
        </div>

        <Address form={form} addressModel={billingInfo} />

        <h4>Shipping/Postal Address:</h4>
        <div className="form-row">
          <div className="col-md-3 mb-2">{render("useSameAddress")}</div>
        </div>

        {!val.useSameAddress && (
          <Address form={form} addressModel={shippingInfo} />
        )}

        <div className="form-row">
          <div className="col-md-6 mb-2">{render("businessCategory")}</div>
          <div className="col-md-6 mb-2">{render("estimatedSpend")}</div>
        </div>

        <h4> Terms and conditions </h4>
        <div className="form-row">
          <div className="col-md-12 mb-2">{render("checkConditions")}</div>
        </div>

        <div className="form-row">
          <div className="col-md-12 mb-2">
            <div className="actions" style={{ textAlign: "left" }}>
              <Button onClick={onSubmit} status={sendingStatus}>
                SUBMIT
              </Button>
              <ErrorMessage
                errors={form.errors}
                summaryMessage="Please review the errors."
              />
              <ErrorMessage errors={error} />
              {/* {post.done() && (
                <span
                  style={{
                    marginLeft: "1.5rem",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Successfully sent!
                </span>
              )} */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

function Address({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return (
    <Fragment>
      <div className="form-row">
        <div className="col-md-4 mb-2">{render("addressFirstName")}</div>
        <div className="col-md-4 mb-2">{render("addressLastName")}</div>
        <div className="col-md-4 mb-2">{render("addressEmail")}</div>
      </div>

      <div className="form-row">
        <div className="col-md-12 mb-2">{render("address")}</div>
      </div>

      <div className="form-row">
        <div className="col-md-3 mb-2">{render("city")}</div>
        <div className="col-md-3 mb-2">{render("state")}</div>
        <div className="col-md-3 mb-2">{render("postalCode")}</div>
        <div className="col-md-3 mb-2">{render("country")}</div>
      </div>
    </Fragment>
  );
}