import React, { useState, useRef } from 'react';
import utils from '../../../utils';
import { debounce } from '../../../lib/utils-core/debounce';
import * as yt from '../../../lib/youtube/iframeApi';
// import { FiVolumeX, FiVolume2 } from 'react-icons/fi';

export function YoutubeNew(props) {
  const youtubeBannerRef = React.useRef(null);
  const [playerStatus, setPlayerStatus] = useState('loading');
  const [playerSize, setPlayerSize] = useState(null);
  // eslint-disable-next-line
  const [ytPlayer, setYtPlayer] = useState(null);
  // const [muted, setMuted] = useState(true);
  const countPlayedRef = useRef(0);
  const { heightSet, videoLinkOrId, playerId } = props.video;
  const videoId = yt.getYoutubeCode(videoLinkOrId);
  const posterImageUrl =
    props.posterImageUrl && props.posterImageUrl !== ''
      ? props.posterImageUrl
      : yt.thumbnailMax(videoId);
  const mobileImageUrl = props.mobileImageUrl;
  const canPlayVideo = () =>
    typeof window !== `undefined` && typeof props.canPlayVideo === `undefined`
      ? window.innerWidth >= 1200
      : !!(typeof props.canPlayVideo === 'function'
          ? props.canPlayVideo()
          : props.canPlayVideo);

  const playerVars = {
    enablejsapi: 1,
    //'autoplay': 1,
    controls: 0,
    loop: 1,
    showinfo: 0,
    rel: 0,
    modestbranding: 1,
    //'origin': typeof window !== `undefined` && window.location.origin,
    allowfullscreen: true,
    wmode: 'transparent',
  };

  React.useEffect(() => {
    const resized = debounce(() => {
      loadVideo();
    });
    loadVideo();
    if (typeof window !== `undefined`)
      window.addEventListener('resize', resized);
    return () => {
      if (typeof window !== `undefined`)
        window.removeEventListener('resize', resized);
    };
    // eslint-disable-next-line
  }, []);

  function loadVideo() {
    if (youtubeBannerRef.current)
      setPlayerSize(getSize(youtubeBannerRef.current, props.sizeCalculator));
    if (canPlayVideo()) {
      yt.loadApi((loadJs) => {
        loadJs ? init() : setTimeout(init, 100);
      });
    }
  }

  function init() {
    let player = null;
    if (typeof window !== `undefined`) {
      player = new window.YT.Player(playerId, {
        events: {
          onStateChange: (e) => {
            switch (e.data) {
              // case window.YT.PlayerState.PLAYING:
              //   break;
              // case window.YT.PlayerState.PAUSED:
              //   break;
              case window.YT.PlayerState.ENDED:
                const countPlayed = countPlayedRef.current;
                if (countPlayed < 3 - 1) {
                  console.log('ended', countPlayed + 1);
                  countPlayedRef.current = countPlayed + 1;
                  if (player && player.playVideo) {
                    player.playVideo();
                  } else {
                    setPlayerStatus('finished');
                  }
                } else {
                  setPlayerStatus('finished');
                }
                break;
              default:
            }
          },
          onReady: (e) => {
            //console.log('onReady');
            if (player && player.mute && player.playVideo) {
              player.mute();
              player.playVideo();
            }
            setTimeout(() => setPlayerStatus('loaded'), 1000);
          },
          onError: (e) => {
            console.log(e);
          },
        },
      });

      setYtPlayer(player);
    }
  }

  // function soundToggle(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (ytPlayer) {
  //     if (muted) {
  //       setMuted(false);
  //       setTimeout(() => {
  //         if (
  //           ytPlayer &&
  //           ytPlayer.pauseVideo &&
  //           ytPlayer.unMute &&
  //           ytPlayer.playVideo
  //         ) {
  //           ytPlayer.pauseVideo();
  //           ytPlayer.unMute();
  //           ytPlayer.playVideo();
  //         }
  //       }, 100);
  //     } else {
  //       setMuted(true);
  //       setTimeout(() => {
  //         if (
  //           ytPlayer &&
  //           ytPlayer.pauseVideo &&
  //           ytPlayer.unMute &&
  //           ytPlayer.playVideo
  //         ) {
  //           ytPlayer.pauseVideo();
  //           ytPlayer.mute();
  //           ytPlayer.playVideo();
  //         }
  //       }, 100);
  //     }
  //   }
  //   //https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
  // }

  function getSize(container, sizeCalculator) {
    const containerSize = container
      ? { w: container.offsetWidth, h: container.offsetHeight }
      : { w: window.innerWidth, h: window.innerHeight };
    if (sizeCalculator) {
      const size = sizeCalculator(containerSize, canPlayVideo());
      if (size) return size;
    }

    const overSize = 1;
    const rate = 9 / 16;
    const w = containerSize.w * overSize;
    const h = containerSize.h * overSize;
    const size = { width: w, height: w * rate, left: 0, top: 0 };
    if (size.height < h) {
      // stretch horizontally
      size.height = h;
      size.width = h / rate;
    }

    //console.log(size)
    size.left = -(size.width - containerSize.w) / 2;
    size.top = -(size.height - containerSize.h) / 2;
    return size;
  }

  const canPlay = canPlayVideo();
  const imageUrl = !canPlay && mobileImageUrl ? mobileImageUrl : posterImageUrl;
  return (
    <React.Fragment>
      <div
        className={utils.classNames(
          'youtubePlayer',
          'youtubePlayerNew',
          `youtubePlayer--${canPlay ? 'canPlay' : 'cannotPlay'}`,
          `youtubePlayer--${playerStatus}`,
          `youtubePlayer--${heightSet}`
        )}
        ref={youtubeBannerRef}
      >
        <div className="youtubePlayer__mask"></div>
        <div
          className="youtubePlayer__poster"
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
        {playerSize && (
          <iframe
            className="youtubePlayer__iframe"
            id={playerId}
            width="640"
            height="390"
            title="Youtube video"
            style={{
              width: `${playerSize.width}px`,
              height: `${playerSize.height}px`,
              top: `${playerSize.top}px`,
              left: `${playerSize.left}px`,
            }}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&${utils.url.toQueryString(
              playerVars
            )}`}
            allow="autoplay; fullscreen"
            frameBorder="0"
          ></iframe>
        )}
        {/* {canPlay && playerStatus === "loaded" && (
          <div className="youtubePlayer__soundIcon" title={muted ? "Sound On" : "Sound Off"} onClick={soundToggle}>
            {muted ? <FiVolume2 /> : <FiVolumeX />}
          </div>
        )} */}
      </div>
    </React.Fragment>
  );
}
