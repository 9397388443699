const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorGreen : '#1a2d13',
  colorLightGreen : '#515e43',
  colorRed : '#8d2b00',
  colorLightRed : '#493b3f',
  colorLightGrey : '#ded4bf',
  colorYellow : '#f9c700'
}

const variables = {
  familyBase: '"futura-pt",sans-serif',
  familyHeader: '"atocha",serif',

  primaryColor: colors.colorRed,
  fontColor: colors.colorLightRed,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;