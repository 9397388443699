import { fb, validators } from "../../../lib/form";

const countryList = [{ name: "Australia", value: "Australia" }];

const businessCategories = [
  { name: "Retail Storefront", value: "Retail Storefront" },
  { name: "Retail Online Only", value: "Retail Online Only" },
  { name: "Hospitality", value: "Hospitality" },
  { name: "Gift Store", value: "Gift Store" },
  { name: "Wholesaler", value: "Wholesaler" },
];

const estimatedSpendList = [
  { name: "$0-$200 per month", value: "$0-$200 per month" },
  { name: "$201-$500 per month", value: "$201-$500 per month" },
  { name: "$501-$1000 per month", value: "$501-$1000 per month" },
  { name: "$1000+ per month", value: "$1000+ per month" },
];

export function getWholesaleRegisterModel(data) {

  const model = fb.group({
    billingAddress: address(data.billingAddress, data.checkReadOnly),
    shippingAddress: addressS(data.shippingAddress, data.checkReadOnly),

    firstName: [
      data.firstName || "",
      [validators.Required()],
      {
        label: "Authorised Contact:",
        type: "text",
        forceUsePlaceholder: "First Name",
        attrs: { readOnly: data.checkReadOnly ? data.checkReadOnly : false },
      },
    ],
    lastName: [
      data.lastName || "",
      [validators.Required()],
      {
        label: "Last Name",
        type: "text",
        style: { className: "last_name" },
        forceUsePlaceholder: "Last Name",
        attrs: { readOnly: data.checkReadOnly ? data.checkReadOnly : false },
      },
    ],
    email: [
      data.email || "",
      data.hideTermsAndConditions && data.hideTermsAndConditions === true
        ? []
        : [validators.Required()],
      {
        label: "Email",
        type: "email",
        forceUsePlaceholder: "Enter Email",
        attrs: { readOnly: data.checkReadOnly ? data.checkReadOnly : false },
      },
    ],
    password: [
      data.email || "",
      data.hideTermsAndConditions && data.hideTermsAndConditions === true
        ? []
        : [validators.Required()],
      { label: "Password", type: "password", forceUsePlaceholder: "Password", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],
    businessPhone: [
      data.businessPhone || "",
      [validators.Required()],
      { label: "Business Phone:", type: "text", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],

    mobilePhone: [
      data.mobilePhone || "",
      [validators.Required()],
      { label: "Mobile Phone:", type: "text", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],

    businessName: [
      data.businessName || "",
      [validators.Required()],
      { label: "Business Name:", type: "text", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],

    abn: [
      data.abn || "",
      [validators.Required()],
      { label: "ABN:", type: "text", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],

    acn: [
      data.acn || "",
      [],
      { label: "ACN:", type: "text", attrs : {readOnly : data.checkReadOnly ? data.checkReadOnly : false } },
    ],

    useSameAddress: [
      data.useSameAddress || true,
      [],
      { label: `Same as Business Address`, type: "checkbox", attrs : {disabled : data.checkReadOnly ? data.checkReadOnly : false } }
    ],

    businessCategory: [
      data.businessCategory || "Retail Storefront",
      [validators.Required()],
      {
        label: "Business Category:",
        type: "select",
        options: businessCategories,
        style: { inline: true },
        attrs : {disabled : data.checkReadOnly ? data.checkReadOnly : false }
      },
    ],
    estimatedSpend: [
      data.estimatedSpend || "Retail Storefront",
      [validators.Required()],
      {
        label: "Estimated Spend:",
        type: "select",
        options: estimatedSpendList,
        style: { inline: true },
        attrs : {disabled : data.checkReadOnly ? data.checkReadOnly : false }
      },
    ],
    checkConditions: [
      data.checkConditions || false,
      data.hideTermsAndConditions && data.hideTermsAndConditions === true
        ? []
        : [validators.Required()],
      {
        label: `I agree to the website terms and conditions.`,
        type: "checkbox",
      },
    ],
    checkToUpdateForm: [
      data.checkToUpdateForm || true,
      [],
      {
        label: `Check to update form`,
        type: "checkbox",
      },
    ],
  });
  return model;
}

export function address(model, checkReadOnly) {
  const data = model || {};
  return fb.group({
    addressFirstName: [
      data.addressFirstName || "",
      [validators.Required()],
      {
        label: "Billing Contact:",
        type: "text",
        forceUsePlaceholder: "First Name",
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    addressLastName: [
      data.addressLastName || "",
      [validators.Required()],
      {
        label: "Billing Contact:",
        type: "text",
        forceUsePlaceholder: "Last Name",
        style: { className: "last_name" },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    addressEmail: [
      data.addressEmail || "",
      [validators.Required(), validators.Email()],
      {
        label: "Billing Email:",
        type: "email",
        forceUsePlaceholder: "Enter Email",
        style: { className: "last_name" },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    address: [
      data.address || "",
      [validators.Required()],
      {
        label: "Business Address:",
        type: "text",
        forceUsePlaceholder: "Street Address",
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],
    city: [
      data.city || "",
      [validators.Required()],
      { label: "City", type: "text", attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    state: [
      data.state || "",
      [validators.Required()],
      { label: "State / Province / Region", type: "text",
      attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    postalCode: [
      data.postalCode || "",
      [validators.Required()],
      { label: "ZIP / Postal Code", type: "text", attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    country: [
      data.country || "Australia",
      [validators.Required()],
      {
        label: "Country",
        type: "select",
        options: countryList,
        style: { inline: true },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],
  });
}

export function addressS(model, checkReadOnly) {
  const data = model || {};
  return fb.group({
    addressFirstName: [
      data.addressFirstName || "",
      [validators.Required()],
      {
        label: "Shipping Contact:",
        type: "text",
        forceUsePlaceholder: "First Name",
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    addressLastName: [
      data.addressLastName || "",
      [validators.Required()],
      {
        label: "Shipping Contact:",
        type: "text",
        forceUsePlaceholder: "Last Name",
        style: { className: "last_name" },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    addressEmail: [
      data.addressEmail || "",
      [validators.Required(), validators.Email()],
      {
        label: "Shipping Email:",
        type: "email",
        forceUsePlaceholder: "Enter Email",
        style: { className: "last_name" },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],

    address: [
      data.address || "",
      [validators.Required()],
      {
        label: "Shipping Address:",
        type: "text",
        forceUsePlaceholder: "Street Address",
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],
    city: [
      data.city || "",
      [validators.Required()],
      { label: "City", type: "text", attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    state: [
      data.state || "",
      [validators.Required()],
      { label: "State / Province / Region", type: "text", attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    postalCode: [
      data.postalCode || "",
      [validators.Required()],
      { label: "ZIP / Postal Code", type: "text", attrs : {readOnly : checkReadOnly ? checkReadOnly : false } }
    ],
    country: [
      data.country || "Australia",
      [validators.Required()],
      {
        label: "Country",
        type: "select",
        options: countryList,
        style: { inline: true },
        attrs : {readOnly : checkReadOnly ? checkReadOnly : false }
      },
    ],
  });
}
