import variables from './cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}

// const firebaseSettings = {
//   apiKey: "AIzaSyBItcsK4c8oSqWgXsiDVyUCcdw3pZKmi2E",
//   authDomain: "australiannativefoodco.firebaseapp.com",
//   projectId: "australiannativefoodco",
//   storageBucket: "australiannativefoodco.appspot.com",
//   messagingSenderId: "200209144198",
//   appId: "1:200209144198:web:cf8b2569d5188fc51b7407"
// };

const firebaseSettings = {
  apiKey: "AIzaSyAetZiNVS9jAq1bdNxpEu8yLy22ejtgYSU",
  authDomain: "ki-fresh-garlic.firebaseapp.com",
  databaseURL: "https://ki-fresh-garlic.firebaseio.com",
  projectId: "ki-fresh-garlic",
  storageBucket: "ki-fresh-garlic.appspot.com",
  messagingSenderId: "14736933462",
  appId: "1:14736933462:web:b1073f5064f9706c"
};

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
};

export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt
}