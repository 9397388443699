//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../..';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';

export function PageTilesFifth(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
    ></Tile>
  ));
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles__fifth',
    item.cssClass || item.anchorName || ''
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const index = props.idx;

  return (
    <div css={style.pageTile__tile} className="pageTile__fifth">
      <SiteLink
        className="pageTile__fifth__content"
        css={style.tile__content}
        to={disableLink ? '' : page.pageUrl}
      >
        <div css={style.tile__flip__wrapper}>
          <div
            className="pageTile__fifth__bg"
            data-aos={index % 2 === 0 ? 'fade-up-right' : 'fade-up-left'}
            css={style.tile__bg}
          >
            <div
              className="pageTile__fifth__bg2"
              css={style.tile__bg2}
              style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
            ></div>
          </div>
          <div
            css={style.tile__details}
            className={`pageTile__fifth__content__details`}
            data-aos={index % 2 === 0 ? 'fade-up-left' : 'fade-up-right'}
          >
            <div className="pageTile__fifth__container">
              {page.pageTitle && page.pageTitle !== '' && (
                <div className="pageTile__fifth__title">{page.pageTitle}</div>
              )}
              <div className="pageTile__fifth__desc">
                {page.tileDesc || page.description}
              </div>
              <div className="btn btn-primary pageTile__fifth__button">
                LEARN MORE
              </div>
            </div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
