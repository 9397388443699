import { css } from '@emotion/core';
import { mq } from '../../../cssInJs';

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden',
  }),
  pageTile__tile: css(
    mq({
      flex: '0 0 100%',
      maxWidth: '100%',
      padding: '10px',
      zIndex: 1,
      '&:nth-of-type(even)': {
        '.pageTile__fifth__bg': mq({
          order: ['0', null, '2'],
        }),
        '.pageTile__fifth__content__details': mq({
          order: '1',
        }),
      },
    })
  ),
  tile__content: css({
    '&:hover': {
      textDecoration: 'none',
      '& .pageTile__fifth__content__details': {
        color: 'white',
        backgroundColor: 'rgb(81, 94, 67)',
      },
      '& .pageTile__fifth__bg2': {
        transform: 'scale(1.05)',
      },
      '& .pageTile__fifth__content__details .pageTile__fifth__container .pageTile__fifth__title':
        {
          color: 'white',
        },
      '& .pageTile__fifth__content__details .pageTile__fifth__container .pageTile__fifth__desc':
        {
          color: 'white',
        },
    },
  }),
  tile__flip__wrapper: css({
    zIndex: '-1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    flexWrap: 'wrap',
  }),
  tile__bg: css(
    mq({
      height: '0',
      paddingTop: ['50%', '35%', '30%'],
      flex: '1 1 300px',
      overflow: 'hidden',
      position: 'relative',
    })
  ),
  tile__bg2: css(
    mq({
      height: '100%',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transition: 'all .2s',
      position: 'absolute',
      top: '0px',
      width: '100%',
    })
  ),
  tile__details: css({
    overflow: 'hidden',
    flexGrow: '1',
    textAlign: 'center',
    width: '100%',
    bottom: '0',
    backgroundColor: 'rgb(222, 212, 191)',
    flex: '1 1 300px',
    transition: 'all .2s ease-in-out',
    color: 'white',
    position: 'relative',
    '& .pageTile__fifth__container': mq({
      position: ['static,', null, 'absolute'],
      top: '50%',
      left: '50%',
      transform: ['none', null, 'translate(-50%, -50%)'],
      width: ['100%', null, '90%'],
      padding: ['1rem', null, '0px'],
      '& .pageTile__fifth__title': {
        fontSize: '1.3rem',
        fontWeight: '700',
        lineHeight: '1',
        margin: '0',
        paddingTop: '.5rem',
        paddingBottom: '1.2rem',
        color: 'rgb(26, 45, 19)',
        fontFamily: '"Roboto Condensed",sans-serif',
      },
      '& .pageTile__fifth__desc': {
        lineHeight: '1.3',
        fontSize: '.9rem',
        minHeight: '70px',
        color: 'rgb(26, 45, 19)',
        fontWeight: 400,
        marginBottom: '1rem',
        fontFamily: '"Roboto Condensed",sans-serif',
      },
    }),
  }),
};
