import { css } from '@emotion/core';
import { mq, variables } from '../../cssInJs';

export default {
  top: css(
    mq({
      height: [44, null, 56],
      background: '#000',
      position: 'fixed',
      width: '100%',
      zIndex: '999',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.3s',
    })
  ),

  line__container: mq({
    position: 'fixed',
    top: ['44px', null, '56px'],
    width: '100%',
    zIndex: 2,
  }),

  topScrolled: css(
    mq({
      background: '#000',
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: 'block',
      height: [100, null, 150],
      marginTop: ['5rem', null, '6.4rem'],
      transition: 'all .2s',
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [87, null, 124],
          marginTop: ['5rem', null, '5.5rem'],
        })
      ),
    })
  ),

  shopping__cart: mq({
    position: 'absolute',
    right: ['50px', null, '100px'],
    color: 'white',
    cursor: 'pointer',
    svg: mq({
      width: '30px',
      height: '30px',
    }),
  }),

  shopping__cart__scrolled: {},

  shopping__cart__num: mq({
    position: 'absolute',
    display: 'flex',
    width: ['20px', null, '30px'],
    height: ['20px', null, '30px'],
    backgroundColor: 'red',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: ['center', 'end'],
    top: ['-3px', null, '-8px'],
    left: ['-9px', null, '-15px'],
  }),
  hr: mq({
    marginTop: '0',
    marginBottom: '0',
    borderTop: ['6px solid', null, '7.5px solid'],
  }),
  hr__1: {
    borderTopColor: variables.colorRed + ' !important',
  },
  hr__2: {
    borderTopColor: variables.colorYellow + ' !important',
  },
};
