import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 2, 3, 3];
const marginTB = [14, 14, 14, 14];
const widthPercent = (index) => 100 / numberOfTiles[index] + '%';
const linkText = {
  color: '#1a2d13',
  fontSize: '0.8rem',
  '&:hover': {
    color: 'white'
  },
};
export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    margin: 'auto',
  }),
  categoryList: mq({
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    margin: 'auto',
    textAlign: 'center',
    'div.ff.form-group': {
      '.ff__input': {
        display: 'inline-block',
        marginLeft: '1rem',
      },
    },
  }),
};

export const tileStyle = {
  tile: mq({
    padding: '0.5rem',
    maxWidth: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexBasis: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexGrow: 0,
    flexShrink: 0,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  }),
  tileLink: {
    background: variables.colorLightGrey,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: variables.colorLightGreen,
      '.product__tile__bg': {
        transform: 'scale(1.05)',
      },
      '.product__tile__name': {
        color: 'white',
      },
      '.product__tile__des': {
        color: 'white',
      },
      '.product__tile__price' : {
        color: 'white'
      }
    },
  },
  container: {
    boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  prdImage: {
    overflow: 'hidden',
  },
  bg: mq({
    paddingTop: ['88%'],
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#f7f7f7',
  }),
  name: css(
    linkText,
    mq({
      marginTop: '1rem',
      // marginBottom: '1rem',
      textDecoration: 'none',
      lineHeight: '1.1',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: '600',
    })
  ),
  des: css(
    linkText,
    mq({
      textDecoration: 'none',
      textAlign: 'center',
      lineHeight: '1.1',
      fontSize: '0.8rem',
      marginTop: '0.5rem',
      flex: '1 0 auto',
      marginBottom: '0.5rem',
    })
  ),
  price: css(
    linkText,
    mq({
      marginTop: '0.2rem',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: ['1rem', null, '0.8rem'],
    })
  ),

  button: css(
    linkText,
    mq({
      marginTop: '0.3rem',
      textAlign: 'center',
      button: {
        fontWeight: '600',
        padding: '10px 30px',
        fontSize: '18px',
        lineHeight: '1',
        letterSpacing: '1px',
      },
    })
  ),

  new: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '50%',
    height: '55px',
    width: '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'rgba(134, 188, 68, 0.6)',
  },

  sale: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '50%',
    height: '55px',
    width: '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'rgba(255, 0, 0, 0.6)',
  },

  portal__background: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 9998,
  },

  portal__popupcontainer: mq({
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '100%',
    zIndex: '9999',
    transform: 'translate(-50%, -50%)',
    maxWidth: ['95%', null, '800px'],
  }),

  related__product__head: {
    textAlign: 'center',
    backgroundColor: 'black',
    position: 'relative',

    img: mq({
      width: 'auto',
      height: ['32px', null, '45px'],
    }),

    svg: {
      marginTop: '-5px',
    },
  },

  related__product__img: {
    minHeight: '400px',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    img: mq({
      //width : 'auto',
      maxWidth: '100%',
      height: 'auto',
      position: 'absolute',
      //top: '50%',
      left: '50%',
      transform: 'translateX(-50%)',
      paddingTop: ['1rem', null, '1rem'],
    }),
  },

  related__product__title: mq({
    fontSize: ['1.3rem', null, '1.5rem'],
    textAlign: 'left',
    marginTop: ['0.5rem', null, '0.5rem'],
    marginBottom: '1rem',
  }),

  related__product__contents: mq({
    marginLeft: '0px',
    marginRight: '0px',
    maxHeight: ['500px', null, '640px'],
    overflow: 'auto',
  }),

  related__product__desc: {
    fontWeight: '300',
    p: {
      fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
      marginBottom: '0.5rem !important',
      lineHeight: '1.1 !important',
    },
  },
};
