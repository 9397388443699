
import { renderRule as bigPanel } from './bigPanel/render';
import { renderRule as code_instagram } from './code-instagram/render';
import { renderRule as code_subscribe } from './code-subscribe/render';
import { renderRule as html } from './html/render';
import { renderRule as imagePanel } from './imagePanel/render';
import { renderRule as memberLogin } from './memberLogin/render';
import { renderRule as pageTiles } from './pageTiles/render';
import { renderRule as panel } from './panel/render';
import { renderRule as photoGallery } from './photoGallery/render';
import { renderRule as productPageTiles } from './productPageTiles/render';
import { renderRule as sharingIcons } from './sharingIcons/render';
import { renderRule as videoPanel } from './videoPanel/render';
import { renderRule as wholesaleRegister } from './wholesaleRegister/render';
export const items = [
  bigPanel,
  code_instagram,
  code_subscribe,
  html,
  imagePanel,
  memberLogin,
  pageTiles,
  panel,
  photoGallery,
  productPageTiles,
  sharingIcons,
  videoPanel,
  wholesaleRegister,
]