/** @jsx jsx */
import { jsx } from "@emotion/core";
//import { useState } from "react";
import utils from "utils";
import style from "./memberLoginStyle";
import { useEffect, useState } from "react";
import { useRouter, Loading, SiteLink } from "../../../../components";
import { useAuth } from "auth";
import { useStore } from '../../../../store';
//import env from "../../../../env";

export function MemberLogin(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "member_login",
    item.cssClass || item.anchorName || ""
  );

  if (cmsOption.isCmsEdit) {
    return (
      <div
        className={cssClass}
        css={style.member_login}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> Member Login </b>
        </pre>
      </div>
    );
  }

  return <LoginView cssClass={cssClass} cmsOption={cmsOption} item={item} />;
}

function LoginView({ cssClass, cmsOption, item }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [checkAuth, setCheckAuth] = useState(false);
  const [error, setError] = useState('');
  const { dispatch } = useStore();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const router = useRouter();

  function signout(e) {
    e.preventDefault();
    e.stopPropagation();

    auth
      .signout()
      .then((user) => {
        //router.push('/admin/login')
        router.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function submit(e) {
    setPending(true);
    e.preventDefault();
    e.stopPropagation();
    auth
      .signin(formValues.email, formValues.password)
      .then((user) => {

        if(user && user.results && user.results.user && user.results.user.firstName ){ 
          dispatch({type: 'SET_STATE', payload: { userFirstName : user.results.user.firstName }});  
        }

        setPending(false);
        setError('');
        dispatch({type: 'SET_STATE', payload: { authCheck : true }});
        dispatch({type: 'SET_STATE', payload: { cartInfo:0 }});
        router.push('/wholesale-dashboard');
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
        setError(error.message);
      });
  }

  function handChange(el) {
    const elName = el.target.name;
    const elValue = el.target.value;
    setFormValues({
      ...formValues,
      [elName]: elValue,
    });
  }

  useEffect(() => {
    const fetch = async () => {
      if (await auth.loggedIn()) {
        setCheckAuth(true);
      }
      setLoading(false);
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={cssClass}
      css={style.member_login}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {checkAuth ? (
        <div>
          <h1 css={style.logged_in_text} className="logged_in_text">
            {" "}
            You are already logged in{" "}
          </h1>
          <button
            className="btn btn-primary btn-sm"
            onClick={(e) => signout(e)}
          >
            Logout
          </button>
        </div>
      ) : (
        <LoginForm
          submit={submit}
          formValues={formValues}
          handChange={handChange}
          pending = {pending}
          error = {error}
        />
      )}
    </div>
  );
}

function LoginForm({ submit, formValues, handChange, pending, error }) {
  return (
    <div style={{maxWidth:'600px', margin:'auto'}}>
      <h1> Member Login </h1>
      <form css={style.form} onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            className="form-control"
            name="email"
            required
            autoFocus
            autoComplete="false"
            value={formValues.email}
            onChange={handChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            className="form-control"
            name="password"
            required
            autoComplete="false"
            value={formValues.password}
            onChange={handChange}
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            disabled={pending}
            className="btn btn-primary btn-block"
          >
            {pending && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="">Log In</span>
          </button>
        </div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </form>
      <div style={{ marginTop: 20 }}>
        <SiteLink css={style.button} to={'/member-forgot-password'}>Forgot Password?</SiteLink>
      </div>
    </div>
  );
}