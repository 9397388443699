import { fb, validators } from '../../../form';

export function getShippingFormData(cart) {
  const data = cart || {};
  
  const shippingMethods = [
    { name: 'Shipping', value: "Standard" },
    { name: 'Pickup', value: "Pickup" }
  ];

  const shippingCountry = [
    { name: 'Australia', value: "Australia" },
    { name: 'International', value: "International" }
  ];

  const model = fb.group({
    //billingInfo: address(data.billingAddress, data.orderType, data.wholeSaleRegAdrBilling, (data.wholeSaleRegister && data.wholeSaleRegister.mobilePhone) ? data.wholeSaleRegister.mobilePhone : null),
    //shippingInfo: address(data.shippingAddress, data.orderType, data.wholeSaleRegAdrShipping, (data.wholeSaleRegister && data.wholeSaleRegister.mobilePhone) ? data.wholeSaleRegister.mobilePhone : null),
    //shippingMethod: [data.shippingMethod || '', [], { label: 'Shipping Method', type: 'text' }],
    shippingMethod: [data.shippingMethod || '', [], { label: null, type: 'radioList', options : shippingMethods, style : {inline : true} }],
    shippingCountry: [data.shippingCountry || 'Australia', [], { label: null, type: 'radioList', options : shippingCountry, style : {inline : true} }],
    useSameAddress: [data.useSameAddress || false, [], { label: `Use the same address`, type: 'checkbox' }],
  });
  return model;
}

export function address(model, orderType, wholeSaleRegAdr, mobilePhone) {
  const data = model || {};
  const _firstName = (data.firstName && data.firstName !== '') ? data.firstName : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.addressFirstName : '';
  const _surname = (data.surname && data.surname !== '') ? data.surname : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.addressLastName : '';
  const _street1 = (data.street1 && data.street1 !== '') ? data.street1 : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.address : '';
  const _suburb = (data.suburb && data.suburb !== '') ? data.suburb : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.city : '';
  const _state = (data.state && data.state !== '') ? data.state : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.state : '';
  const _postcode = (data.postcode && data.postcode !== '') ? data.postcode : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.postalCode : '';
  const _email = (data.email && data.email !== '') ? data.email : (orderType === 1 && wholeSaleRegAdr) ? wholeSaleRegAdr.addressEmail : '';
  const _mobile = (data.mobile && data.mobile !== '') ? data.mobile : (orderType === 1) ? mobilePhone : '';

  return fb.group({
    firstName: [_firstName, [validators.Required()], { label: 'First Name', type: 'text' }],
    surname: [_surname, [validators.Required()], { label: 'Surname', type: 'text' }],
    street1: [_street1, [validators.Required()], { label: 'Street', type: 'text' }],
    street2: [data.street2 || '', [], { label: null, type: 'text' }],
    suburb: [_suburb, [validators.Required()], { label: 'Suburb', type: 'text' }],
    state: [_state, [validators.Required()], { label: 'State', type: 'text' }],
    postcode: [_postcode, [validators.Required()], { label: 'Postcode', type: 'text' }],
    email: [_email, [validators.Required(), validators.Email()], { label: 'Email Address', type: 'email' }],
    mobile: [_mobile, [validators.Required()], { label: 'Mobile', type: 'tel' }]
  })
}

// public AddressModel ShippingInfo { get; set; }
// public AddressModel BillingInfo { get; set; }
// public string ShippingMethod { get; set; }
// public bool UseSameAddress { get; set; }
// public string SpecialRequest { get; set; }

// public string FirstName { get; set; }
// public string Surname { get; set; }
// public string AddressLine { get; set; }
// public string Suburb { get; set; }
// public string State { get; set; }
// public string Postcode { get; set; }
// public string Country { get; set; }
// public string CountryCode { get; set; }
// public string Email { get; set; }
// public string Phone { get; set; }
// public string Mobile { get; set; }