import { fb } from '../../../lib/form';
export function getCategoryListModel(data, defaultCategories, mainCategory) {
  let select_mainCategory = data.filter((d) => d.categoryId === mainCategory);
  select_mainCategory = { ...select_mainCategory[0], selected: true };

  let mainCategory_object = {
    name: select_mainCategory.categoryName,
    value: select_mainCategory,
  };

  let options = [
    { name: mainCategory_object.name, value: select_mainCategory.categoryId },
    // { name: 'All', value: '' },
    { name: 'Favourites', value: '_favourites_' },
  ];

  if (data.length > 0) {
    data.forEach((el) => {
      if (el.categoryId === mainCategory) {
        options.push({
          name: select_mainCategory.categoryName,
          value: select_mainCategory.categoryId,
          selected: select_mainCategory.selected,
        });
      } else {
        options.push({ name: el.categoryName, value: el.categoryId });
      }
    });
  }

  let categoryList = '';
  if (defaultCategories.length === 1) {
    categoryList = defaultCategories[0];
  }

  const model = fb.group({
    categoryList: [
      categoryList || '',
      [],
      {
        label: 'FILTER BY',
        type: 'select',
        options: options,
      },
    ],
  });

  return model;
}
