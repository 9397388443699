import { mq, variables } from '../../../../cssInJs';

export default {
  noResultText: {
    textAlign: 'center',
    marginTop: '1rem',
    fontWeight: 600,
  },
  product__page__tiles: {
    '&.slider': {
      '.product__tile__item': {
        maxWidth: '370px',
        flexBasis: '100%',
        marginTop: '0px',
        marginBottom: '0px',
        height: '100%',
      },
      '.slick-next:before, .slick-prev:before': {
        color: variables.colorGreen,
      },
      '.slick-prev': mq({
        left: ['-5px', null, '-22px'],
      }),
      '.slick-next': mq({
        right: ['-5px', null, '-22px'],
      }),
    },
  },
  product__page__tiles__container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '7px',
    marginBottom: '7px',
    margin: 'auto',
  },
};
