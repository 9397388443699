/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./cartStyle";
import React, { Fragment, useEffect } from "react";

//import { SiteLink } from '../../../components';
import env from "../../../env";
import { usePost, Loading, ErrorPopup, useRouter } from "../../../components";
import { useForm } from "../../../form";
import { useStep } from "./useStep";
import { StepNav } from "../cartStep/stepNav";

import { OrderItems } from "../cartOrderItems/orderItems";
import { getOrderItemFormData } from "../cartOrderItems/orderItemFormData";
import { Shipping } from "../cartShipping/shipping";
import { getShippingFormData } from "../cartShipping/shippingFormData";
import { Pay } from "../cartPayment/pay";
import utils from "../../../utils";
import { useAuth } from "auth";
import { useStore } from "../../../store";

export function Cart() {
  const auth = useAuth();
  const [checkAuth, setCheckAuth] = React.useState(null);
  const { query } = useRouter();
  const canceled = query.canceled || "";
  const errorMsg = query.errormsg || "";

  useEffect(() => {
    const fetch = async () => {
      if (await auth.loggedIn()) {
        setCheckAuth(true);
      } else {
        setCheckAuth(false);
      }
    };
    fetch();

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment> 
      {
        canceled === "true" && errorMsg !== '' && <div>{'Payment error - ' + errorMsg}</div>
      }
      {checkAuth === null ? (
        <Loading />
      ) : (
        <CartMainView checkAuth={checkAuth} auth = {auth} />
      )}
    </Fragment>
  );
}

function CartMainView({ checkAuth, auth }) {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [cart, setCart] = React.useState(null);
  const { dispatch } = useStore();

  //const get = useGet();
  const post = usePost();

  React.useEffect(() => {
    let _cartId = env.getDevCartId();
    const value = {
      cartId: _cartId === "null" ? null : _cartId,
      auth: checkAuth,
    };

    post.send(env.apiBase + "/api/cart/getCart", value);
    setSendingStatus("pending");
    
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    if (
      post.errors.length > 0 &&
      post.errors[0] === "Permission, this page will reload"
    ) {
      auth.signout().then((user) => {
        dispatch({ type: "SET_STATE", payload: { authCheck: false } });
        dispatch({ type: "SET_STATE", payload: { cartInfo: 0 } });
        dispatch({ type: 'SET_STATE', payload: { userFirstName : "" }});
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
    return <ErrorPopup title="Loading Error" errors={post.errors} />;
  }

  if (sendingStatus === "pending") {
    setCart(post.response);
    setSendingStatus(null);
  }

  if (!cart || !cart.cartInfo || cart.cartInfo.totalQuantity === 0) {
    return <EmptyCart />;
  }
  return <CartView cart={cart} setCart={setCart} checkAuth={checkAuth} />;
}

function CartView({ cart, setCart, checkAuth }) {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [goNext, setGoNext] = React.useState(false);
  const [action, setAction] = React.useState("");
  const post = usePost();
  const stepInfo = useStep([
    { id: "orderItems", title: "Cart" },
    { id: "shipping", title: "Your Details" }
  ]);
  const { steps, currentStep, lastStep, moveNext, markAsFilled } = stepInfo;

  const orderItemForm = useForm(getOrderItemFormData(cart), {
    onControlChanged: orderItemChanged,
  });
  const shippingForm = useForm(getShippingFormData(cart), {
    onControlChanged: orderItemChanged,
  });
  const { dispatch } = useStore();

  function orderItemChanged(e, control) {
    if (control && control.name === "quantity") {
      const index = orderItemForm
        .findControl("items")
        .findIndexById(control.parent.id);
      const orderItem = cart.orderItems[index];
      const value = {
        qty: control.value,
        orderItemId: orderItem.orderItemId,
        cartId: env.getDevCartId(),
        auth: checkAuth,
      };
      post.send(env.apiBase + "/api/cart/updateQty", value);
      setSendingStatus("pending");
      setGoNext(false);
    } else if (
      control &&
      (control.name === "shippingMethod" || control.name === "shippingCountry")
    ) {
      // shippingForm.findControl("useSameAddress").value = false;
      // if (control.value === "Standard") {
      //   shippingForm.findControl("useSameAddress").value = true;
      // }
      const value = {
        ...shippingForm.getValue(),
        cartId: env.getDevCartId(),
        auth: checkAuth,
      };
      post.send(env.apiBase + "/api/cart/saveShippingInfo", value);
      setSendingStatus("pending");
      setAction("");
      setGoNext(false);
    }
  }

  function remove(e, index) {
    console.log(index);
    const orderItem = cart.orderItems[index];
    const value = {
      orderItemId: orderItem.orderItemId,
      cartId: env.getDevCartId(),
      auth: checkAuth,
    };
    post.send(env.apiBase + "/api/cart/removeItem", value);
    setSendingStatus("pending");
    setAction("");
    setGoNext(false);
  }

  function saveShipping(e) {
    //const value = { ...shippingForm.getValue(), useSameAddress: true, cartId: env.getDevCartId() };
    const value = {
      ...shippingForm.getValue(),
      cartId: env.getDevCartId(),
      auth: checkAuth,
    };
    //console.log("valu", value);
    post.send(env.apiBase + "/api/cart/saveshippinginfo", value);
    setSendingStatus("pending");
    setAction("saveShipping");
    //setGoNext(true);
  }

  React.useEffect(() => {
    
    if (post.done() && sendingStatus === "pending" && action === "saveShipping"){  
      var checkOutForm = document.getElementById("stripecheckoutform");
      checkOutForm.innerHTML = "";

      var cartId = document.createElement("input");
      cartId.value = env.getDevCartId();
      cartId.name = "cartId"; 
      checkOutForm.appendChild(cartId);
      checkOutForm.submit(); 
      return;
    }

    if (post.done() && sendingStatus === "pending") {

      const cart = post.response.results.cartModel;
      setSendingStatus(null);
      setCart(cart);
      if (goNext) {
        markAsFilled(currentStep);
        moveNext();
      }
      dispatch({
        type: "SET_STATE",
        payload: {
          cartInfo:
            post.response.results.cartInfo.totalQuantity === 0
              ? 0
              : post.response.results.cartInfo.totalQuantity - 1,
        },
      });
    }

    // eslint-disable-next-line
  }, [post, currentStep]);

  const stepStyle = (index) => {
    const locStyle =
      index === currentStep
        ? style.stepCurrent
        : index === currentStep - 1
        ? style.stepPrev
        : index === currentStep + 1
        ? style.stepNext
        : style.step;
    const movingStyle = index === lastStep ? style.stepLast : null;
    return [locStyle, movingStyle];
  };

  const next = () => {
    markAsFilled(currentStep);
    moveNext();
    utils.ui.scrollTo("#ecmcart");
  };

  return (
    <div css={style.cart} id="ecmcart">
      <div css={style.stepsWrapper}>
        {cart.orderType === 1 && <div> Wholse sale order</div>}
        <StepNav stepInfo={stepInfo} />
        <div
          css={style.steps}
          style={{ transform: `translateX(${-100 * currentStep}%)` }}
        >
          {steps.map((step, index) => {
            let stepView = null;
            switch (step.id) {
              case "orderItems":
                stepView = (
                  <OrderItems
                    cart={cart}
                    form={orderItemForm}
                    moveNext={next}
                    post={post}
                    remove={remove}
                  />
                );
                break;
              case "shipping":
                stepView = (
                  <Shipping
                    cart={cart}
                    form={shippingForm}
                    saveShipping={saveShipping}
                    post={post}
                    action={action}
                  />
                );
                break;
              case "payment":
                stepView = <Pay cart={cart} post={post} />;
                break;
              default:
                break;
            }
            return (
              <div
                css={stepStyle(index)}
                key={step.id}
                className={`ecm_cart_${step.id}`}
              >
                {stepView}
                <form id = "stripecheckoutform" style={{display:'none'}} action={`${env.apiBase}/api/cart/payStripecheckout`} method="POST"></form>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function EmptyCart() {
  return (
    <div css={style.cart}>
      <div css={style.stepsWrapper}>
        <h3 style={{ fontFamily: '"proxima-nova", sans-serif' }}>
          {" "}
          Your cart is empty.{" "}
        </h3>
      </div>
    </div>
  );
}
