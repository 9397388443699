import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    // lineHeight: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    color: '#ddd',
    fontSize: ['20px', null, '30px'],
    '&:hover': {
      textDecoration: 'none'
    }
  })),
  back_text: css(mq({
    fontSize: '14px',
  }))
}