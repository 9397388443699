//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
    ></Tile>
  ));
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles__big',
    item.cssClass || item.anchorName || ''
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles__big}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div css={style.pageTile__big} className="pageTile__big">
      <SiteLink
        className="pageTile__big__content"
        css={style.pageTile__big__content}
        to={disableLink ? '' : page.pageUrl}
      >
        <div
          css={style.pageTile__big__wrapper}
          className="pageTile__big__wrapper"
        >
          <div style={{ overflow: 'hidden' }}>
            <div
              className="pageTile__big__bg"
              css={style.pageTile__big__bg}
              style={{
                backgroundImage: utils.css.bgUrlStyle(imageUrl),
                backgroundColor: 'white',
                backgroundSize: 'cover',
              }}
            ></div>
          </div>
          <div
            className="pageTile__big__title"
            css={style.pageTile__big__title}
          >
            {page.pageTitle}
          </div>
          <div
            css={style.pageTile__big__button}
            className="pageTile__big__button"
          >
            <button className="btn btn-primary">SHOP NOW</button>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
