//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../..';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from 'react-slick';

export function PageTilesFourth(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, 'UseSlider') || 'default';
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
      useSlider={useSlider}
    ></Tile>
  ));
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles__fourth',
    useSlider,
    item.cssClass || item.anchorName || ''
  );
  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles__fourth}
    >
      {useSlider && useSlider === 'slider' ? (
        <Slider {...settings}>{pageTileElts}</Slider>
      ) : (
        pageTileElts
      )}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  return (
    <div
      css={style.pageTile__fourth}
      className={`pageTile__fourth ${props.useSlider}`}
    >
      <SiteLink
        className="pageTile__fourth__content"
        css={style.pageTile__fourth__content}
        to={disableLink ? '' : page.pageUrl}
      >
        <div
          css={style.pageTile__fourth__wrapper}
          className="pageTile__fourth__wrapper"
        >
          <div style={{ overflow: 'hidden' }}>
            <div
              className="pageTile__fourth__bg"
              css={style.pageTile__fourth__bg}
              style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
            ></div>
          </div>

          <div
            className="pageTile__big__title"
            css={style.pageTile__fourth__title}
          >
            {page.pageTitle}
          </div>

          <div
            className="pageTile__fourth__des"
            css={style.pageTile__fourth__des}
          >
            {page.tileDesc}
          </div>

          <div
            css={style.pageTile__fourth__button}
            className="pageTile__fourth__button"
          >
            <button className="btn btn-primary">READ MORE</button>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
