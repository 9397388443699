import { mq, variables } from '../../../cssInJs';

export default {
    pageTiles__small: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden',
        '&.slider' : {
            display : 'block',
            overflow: 'unset',
            '.slick-prev:before, .slick-next:before' : {
                color: 'black'
            },
            '.slick-prev' : {
                left: '-22px'
            },
            '.slick-next' : {
                right: '-22px'
            }
        } 
    },
    pageTile__small: mq({
        flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%', '1 0 25%'],
        maxWidth: ['100%', null, '50%', '33.3%', '25%'],
        padding: '10px',
        '&.slider' : {
            //flex : '1 0 100%',
            maxWidth : '100%',
            height : '100%'
        }
    }),
    pageTile__small__wrapper: {
        boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'rgb(222, 212, 191)'
    },
    pageTile__small__content: {
        '&:hover': {
            textDecoration: 'none',
            color : 'white',
            '.pageTile__big__inner__title' : {
                color : 'white'
            },
            '.pageTile__small__inner__des' : {
                color : 'white'
            },
            '.pageTile__small__wrapper' : {
                backgroundColor: 'rgb(81, 94, 67)',
            },
            '.pageTile__small__bg': {
                transform: 'scale(1.05)'
            }
        }
    },
    pageTile__small__title: mq({
        fontSize: ['32px',null,'23px'],
        lineHeight: '1.1',
        padding: '0.5rem 0.5rem',
        //flex: '1 0 auto',
        color : variables.fontColor,
        minHeight: '72px',
        fontWeight : '600',
        maxWidth : '84%',
        '.pageTile__big__inner__title' : {
            textOverflow: 'ellipsis',
            display : '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        }
    }),
    pageTile__small__bg: {
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'all .2s'
    },
    pageTile__small__des: {
        fontSize: '18px',
        lineHeight: 1.2,
        padding: '0.5rem 0.5rem',
        color: variables.fontColor,
        flex: '1 0 auto',
        '.pageTile__small__inner__des' : {
            textOverflow: 'ellipsis',
            display : '-webkit-box',
            WebkitLineClamp: '5',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        }
    },
    pageTile__small__button: {
        padding: '0rem 0.5rem 0.5rem',
        'button': {
            //backgroundColor: 'transparent',
            
            //color: variables.fontColor,
            //borderColor: variables.colorDarkGray,
            
            fontWeight: '600',
            padding : '14px 30px',
            fontSize : '18px',
            lineHeight: '1',
            letterSpacing: '1px'
        }
    }
}