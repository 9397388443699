//import { css } from '@emotion/core';
import variables from '../../../cssInJs/variables';
import { mq } from '../../../cssInJs';
export default {
  product__banner: mq({
    // backgroundColor: 'black',
    backgroundImage: 'url("/sites/anfc/media/misc/native-food-in-jars.jpg")',
    height: ['92px', null, '207px'],
  }),

  desc: {
    fontWeight: '300',
    p: {
      fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
      marginBottom: '0.5rem !important',
      lineHeight: '1.1 !important',
    },
    li: {
      fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
    },
  },
  heroImageContainer: mq({
    position: 'relative',
  }),
  heroImage: mq({
    width: '100%',
  }),
  productPageCol: mq({
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '20px',
    '.add__to__quote': {
      color: variables.colorGreen,
      backgroundColor: 'transparent',
      marginLeft: '1rem',
      svg: {
        color: 'transparent',
        stroke: variables.colorGreen,
        strokeWidth: '2',
      },
    },
  }),
  productFavorite: {
    cursor: 'pointer',
    '&.checked': {
      color: 'red',
    },
  },
  productBreadCrumbs: mq({
    padding: '0px',
    listStyle: 'none',
    marginBottom: '0.5rem',
    display: ['none', null, 'block'],
    li: mq({
      display: 'inline-block',
      marginRight: '1.5rem',
      position: 'relative',
      fontSize: '0.7rem',
      '&:not(:last-child)::after, &.breadCrumb.last-item::after': {
        content: '">"',
        position: 'absolute',
        right: '-1rem',
      },
      '&.breadCrumb::after': {
        content: '"|"',
        position: 'absolute',
        right: '-1rem',
      },
    }),
  }),
  productViewImageBg: mq({
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    paddingTop: ['80px', '120px'],
    paddingBottom: ['3rem', '2rem'],
    backgroundRepeat: 'no-repeat',
    marginLeft: '5px',
    marginRight: '5px',
    position: 'relative',

    '&:not(.current):before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      backgroundColor: 'rgba(255,255,255,0.6)',
    },
  }),
  productViewImages: mq({
    marginTop: ['1rem', '2rem'],
    paddingBottom: ['1rem', '2rem'],
    marginLeft: '-5px',
    marginRight: '-5px',
    '.slick-prev, .slick-next': mq({
      transform: 'none',
      top: ['95px', null, '150px'],
      '&:before': {
        color: '#999999',
      },
    }),
    '.slick-prev': {
      left: '150px',
    },
    '.slick-next': {
      right: '150px',
    },
  }),

  productView__images__container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '.productView__image': mq({
      flex: ['1 0 33.3%', null, '1 0 25%'],
      maxWidth: ['33.3%', null, '25%'],
      //position: 'relative',
    }),
  },

  productViewImage: {
    //paddingLeft: '5px',
    //paddingRight: '5px'
    outline: 'none',
    cursor: 'pointer',
  },

  productViewShortDes: mq({
    fontSize: ['1rem'],
    textAlign: 'center',
  }),

  productViewTitle: mq({
    fontSize: ['1.7rem'],
    textAlign: 'left',
    marginTop: ['2rem', null, '0px'],
    marginBottom: '1rem',
    // color: '#767676',
  }),

  product__view__shot__des: mq({
    color: '#8d2b00',
    marginBottom: '1rem',
    fontFamily: '"futura-pt",sans-serif',
    lineHeight: '1.1rem',
  }),

  addToCartBtn: {
    'button, .btn': {
      width: '100%',
      backgroundColor: 'black',
      border: '1px solid black',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
      },
      //transform: 'skewX(-12deg)'
    },
    // 'button.gotocartbtn' : {
    //   marginTop: '1rem'
    // }
  },
  priceText: {
    fontSize: '0.8rem',
  },
  socialMedia: {
    '.social-icon-text': {
      fontSize: '0.8rem',
    },
  },
  attributesRow: {
    cursor: 'pointer',
    //marginTop: '0.5rem',
    //marginBottom: '0.5rem',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    '&:hover': {
      backgroundColor: '#999999',
      color: 'white',
    },
    '.svgContainer svg': {
      transition: 'all 0.5s',
    },
    '.svgContainer.open svg': {
      transform: 'rotate(180deg)',
    },
  },

  attrContainer: {
    maxHeight: '0px',
    transition: 'all 0.3s',
    overflow: 'hidden',
    '&.open': {
      maxHeight: '1000px',
    },
    '.form-control:focus': {
      borderColor: 'black',
      boxShadow: 'none',
    },
    '.form-control': {
      paddingTop: '0',
      paddingBottom: '0',
      height: 'calc(1em + .75rem + 2px)',
    },
  },

  desktopMobile: {
    '&.mobile': mq({
      display: ['block', 'none'],
    }),
    '&.desk__top': mq({
      display: ['none', 'block'],
    }),
  },
  quoteContainer: {
    marginBottom: '1rem',
    '.quote__text': {
      fontSize: '0.8rem',
      marginBottom: '1rem',
    },
    button: {
      //transform: 'skewX(-12deg)'
    },
  },
  enquiryFormContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    'form.contactForm': {
      position: 'absolute',
      width: '100%',
      maxWidth: '900px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      //paddingTop: '2rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      margin: '0px',
      paddingBottom: '1rem',
      '.closeButton': {
        textAlign: 'right',
        //margin: '1rem 0px',
        cursor: 'pointer',
        //color: 'white',
        marginTop: '0.5rem',
      },
      '.enquireNowText': {
        marginBottom: '0.5rem',
        //color: 'white'
      },
    },
  },

  newOptionRow: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },

  newOptionStyleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  newOptionStyleItem: {
    flex: '1 0 16%',
    maxWidth: '16%',
    border: '1px solid #ebebeb',
    textAlign: 'center',
    cursor: 'pointer',
    lineHeight: 1,
    fontSize: '0.8rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.4rem',
    fontWeight: 600,
    marginLeft: '-1px',
    marginTop: '-1px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.selected': {
      backgroundColor: 'black',
      color: 'white',
    },
  },

  productViewPanel: {
    // backgroundColor: 'white',
    backgroundImage: 'url("/sites/anfc/media/misc/anfc-back.jpg")',
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    backgroundPosition: '50%',
    //paddingTop : '3rem'
  },

  productView__relatedProducts: {
    '.productView__relatedProducts__head__title': {
      textAlign: 'center',
    },
  },

  variant__enquire__container: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },

  variant__enquire__item: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },

  variant__enquire__title: {
    fontWeight: '600',
  },

  variant__enquire__options: {
    display: 'flex',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
  },

  variant__enquire__option: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },

  product__enquiry__form__item: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '560px',
    overflow: 'auto',
  },

  product__enquiry__form__item__1: {
    display: 'flex',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    '.product__enquiry__form__item__1__img': {
      maxWidth: '60px',
    },
    '.product__enquiry__form__item__1__titile': {
      marginLeft: '0.5rem',
      fontSize: '0.8rem',
      display: 'flex',
      //justifyContent: 'center',
      //alignItems : 'center',
      maxWidth: '180px',
      lineHeight: '1.1',
      flexDirection: 'column',
      //flexWrap : 'wrap'
    },
    '.product__enquiry__form__item__1__button': {
      flex: '1 0 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      //textAlign: 'right',
      button: {
        fontSize: '0.8rem',
      },
    },
  },

  pdf__viewer__bg: {
    //backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: '#ebebeb',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9996',
  },

  pdf__viewer__view: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9997',
    '.react-pdf__message.react-pdf__message--error': {
      padding: '0.5rem',
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 700,
    },
    '.react-pdf__Page__annotations.annotationLayer': {
      display: 'none',
    },
  },

  pdf__viewer__view__bar: {
    backgroundColor: 'black',
    padding: '1rem',
    color: 'white',
    fontWeight: 700,
    position: 'relative',
    '.pdf__viewer__view__bar__pdf__download': {
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },

  pdf__viewer__view__contetns: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: '3rem',
  },

  pdf__viewer__view__contetns__des: {},

  pdf__viewer__view__page: {
    paddingTop: '1.5rem',
    backgroundColor: '#ebebeb',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  pdf__viewer__view__index: {
    position: 'absolute',
    top: '3px',
    left: '10px',
    fontSize: '0.8rem',
  },
};
