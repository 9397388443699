/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "../../../utils";
import { SiteLink, useGet } from "../../../components";
import { tileStyle } from "./productTileStyle";
import { Fragment, useState, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import env from "../../../env";
import { AddToCart } from "../product/addToCart";
import { AiOutlineClose } from "react-icons/ai";
// import { CardImgOverlay } from 'reactstrap';

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductTile({ product, openClikcPopup = false, checkAuth = false }) {
   
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl
    ? utils.site.resourcePath(product.thumbnailUrl)
    : "/assets/product-empty.png";
  const [openProductViewPopup, setOpenProductViewPopup] = useState(false);
  const productUrl = product.productUrl
    ? product.productUrl
    : `/product/${product.productSlug}`;
  function openProductViewPopupF() {
    setOpenProductViewPopup(true);
    document.body.style.overflow = "hidden";
  }
  function closeProductViewPopupF() {
    setOpenProductViewPopup(false);
    document.body.style.overflow = "unset";
  }
  return (
    <Fragment>
      {openProductViewPopup && (
        <Portal>
          <ProductViewPopup
            s={s}
            closeProductViewPopupF={closeProductViewPopupF}
            product={product}
          />
        </Portal>
      )}
      <div css={[s.tile]} className="product__tile__item">
        {openClikcPopup === false ? (
          <SiteLink css={s.tileLink} to={productUrl}>
            <TileContetns product={product} s={s} imageUrl={imageUrl} checkAuth = {checkAuth} />
          </SiteLink>
        ) : (
          <div
            css={s.tileLink}
            onClick={() => {
              openProductViewPopupF();
            }}
          >
            <TileContetns product={product} s={s} imageUrl={imageUrl} checkAuth = {checkAuth} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

function TileContetns({ product, s, imageUrl, checkAuth }) {
  
  console.log("checkAuth ---- ", checkAuth);
  
  const saleCheck = useMemo(() => {
    let checkSale = false;
    if (product.productCategories && product.productCategories.length > 0) {
      for (let i in product.productCategories) {
        if (product.productCategories[i].categoryName === "On Sale") {
          checkSale = true;
          break;
        }
      }
    }
    return checkSale;
  }, [product]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div css={s.container}>
        <div css={s.prdImage}>
          <div
            css={s.bg}
            className="product__tile__bg"
            style={{
              backgroundImage: utils.css.bgUrlStyle(imageUrl),
              backgroundColor: "white",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div css={s.name} className="product__tile__name">
          {product.productName}
        </div>
        <div css={s.des} className="product__tile__des">
          {product.shortDesc}
        </div>

        <div css={s.price} className="product__tile__price">
          {product.productStatus !== 0
            ? product.productStatusText
            : product.priceTextOveriding
            ? product.priceTextOveriding
            : "AU" + utils.money.formatMoney( checkAuth === true ? ((product.wholesalePriceIncTax && product.wholesalePriceIncTax !== '') ? product.wholesalePriceIncTax : product.priceIncTax ) : product.priceIncTax)}
        </div>

        <div css={s.button}>
          <button className="btn btn-primary"> Buy Now </button>
        </div>
      </div>
      {product && product.checkNew && <div css={s.new}>NEW</div>}
      {product && saleCheck === true && <div css={s.sale}>SALE</div>}
    </div>
  );
}

function ProductViewPopup({ s, closeProductViewPopupF, product }) {
  const get = useGet();
  useEffect(() => {
    const url = `/api/product/getProduct/${product.productSlug}`;
    get.send(env.apiBase + url);
    
    // eslint-disable-next-line
  }, []);
  
  const data = get.response;

  return (
    <Fragment>
      <div
        css={s.portal__background}
        className="portal__background"
        onClick={closeProductViewPopupF}
      ></div>
      <div css={s.portal__popupcontainer} className="portal__popupcontainer">
        <div css={s.related__product__head} className="related__product__head">
          <img src="/assets/logos/logo.png" alt="logo" />
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: "0px",
              transform: "translateY(-50%)",
              color: "white",
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={closeProductViewPopupF}
          >
            <AiOutlineClose />
          </div>
        </div>
        {data ? (
          <div
            css={s.related__product__contents}
            className="related__product__contents row"
          >
            <div
              className="col-12 col-md-5"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div
                css={s.related__product__img}
                className="related__product__img"
              >
                {/* <img src={data.thumbnailUrl} alt={`img`} /> */}
                <img src={data.thumbnailUrl} alt={`img`} />
              </div>
            </div>
            <div
              className="col-12 col-md-7"
              style={{ backgroundColor: "white" }}
            >
              <h1
                className="related__product__title"
                style={{ textTransform: "uppercase" }}
                css={s.related__product__title}
              >
                <strong>{data.productName}</strong>
              </h1>
              <div
                className="related__product__desc"
                css={s.related__product__desc}
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
              {data.productStatus === 0 && <AddToCart product={data} />}
            </div>
          </div>
        ) : (
          <div
            css={s.related__product__contents}
            className="related__product__contents row"
          >
            <div className="col-12"> Loading ... </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}