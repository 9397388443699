import React from "react";
import env from "env";
import { usePost } from "components";
import { SiteLink } from "components";
import { useRouter } from "components";
import { ErrorPopup } from "components";
import { Button, ErrorMessage } from "components";
//import { IoReturnUpBack } from "react-icons/io5";

export function ResetPassword() {
  const [password, setPassword] = React.useState("");
  const { query } = useRouter();
  const token = (query && query.u) || "";
  const post = usePost();

  if (!token) {
    return (
      <ErrorPopup
        title="Invalid page"
        errors={
          "This is not a valid URL for password reset. Please follow the link from your email."
        }
      >
        Go back to{" "}
        <SiteLink to="/member/login">
          <span>LOG IN</span>
        </SiteLink>
      </ErrorPopup>
    );
  }

  function onSubmit(e) {
    post.send(env.apiBase + "/api/profile/resetpassword", {
      token: token,
      password: password,
    });
  }
  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: 'url("/sites/anfc/media/misc/anfc-back.jpg")',
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        backgroundPosition: "50%",
      }}
    >
      <div style={{backgroundImage:'url("/sites/anfc/media/misc/native-food-in-jars.jpg")', height : '150px'}}></div>
      <div
        className="forgot-password auth-panel"
        style={{ maxWidth: "600px", width: "90%", margin: "auto" }}
      >
        <h1>Reset Password</h1>
        <div className="loading-panel-holder">
          <div className="panel-content">
            <form method="POST" noValidate="" onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="email">New Password</label>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  required
                  autoComplete="false"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Password is invalid</div>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <div className="form-group m-0">
                  <Button onClick={onSubmit} type="submit" status={post.status}>
                    Reset Password
                  </Button>
                  {post.done() && (
                    <div style={{ color: "chocolate", marginTop: "0.5rem" }}>
                      <b>Successfully reset your password.</b>
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage errors={post.errors} />
            </form>
          </div>
        </div>

        <div style={{ marginTop: "2rem" }}>
          Go back to{" "}
          <SiteLink to="/member-login">
            <span>LOG IN</span>
          </SiteLink>
        </div>
      </div>
    </div>
  );
}
