import React from "react";
import env from "env";
import { usePost } from "components";
import { Button, ErrorMessage } from "components";
import { SiteLink } from "components";
// import { IoReturnUpBack } from 'react-icons/io5';

export function ForgotPassword() {
  const [username, setUsername] = React.useState("");
  const post = usePost();
  function onSubmit(e) {
    post.send(env.apiBase + "/api/profile/sendpasswordresetemail", {
      email: username,
    });
  }
  return (
    <div style={{height : '100vh', backgroundImage:'url("/sites/anfc/media/misc/anfc-back.jpg")', backgroundSize : '100%', backgroundRepeat : 'repeat', backgroundPosition : '50%'}}> 
      <div style={{backgroundImage:'url("/sites/anfc/media/misc/native-food-in-jars.jpg")', height : '150px'}}></div>
      <div
        className="forgot-password auth-panel"
        style={{ maxWidth: "600px", width: "90%", margin: "auto" }}
      >
        <h1>Forgot Password</h1>
        <div className="loading-panel-holder">
          <div className="panel-content">
            <form method="POST" noValidate="" onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  required
                  autoFocus
                  autoComplete="false"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Email is invalid</div>
              </div>

              <div className="form-text text-muted">
                By clicking "Reset Password" we will send a password reset link
              </div>
              <div style={{ marginTop: "1rem" }}>
                <div className="form-group m-0">
                  <Button onClick={onSubmit} type="submit" status={post.status}>
                    Reset Password
                  </Button>
                  {post.done() && (
                    <div style={{ color: "chocolate", marginTop: "0.5rem" }}>
                      <b>Nearly there!</b> Password reset email sent, check your
                      inbox including your junk folders.
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage errors={post.errors} />
            </form>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          Go back to{" "}
          <SiteLink to="/member-login" className="button_log_in">
            <span>LOG IN</span>
          </SiteLink>
        </div>
      </div>
    </div>
  );
}