import React, { useContext, createContext } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import config from 'config';
import env from 'env';
import { getFirebaseUser } from './authHelper';
import { fetchPost } from '@lib/net-api/fetch';

firebase.initializeApp(config.firebaseSettings);

const authContext = createContext();

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  //console.log('ProvideAuth')
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [authData, setAuthData] = React.useState({ user: null, queryStatus: 'loading', memberInfo: null });
  const user = authData.user;
  const setUser = (user) => setAuthData({ ...authData, user: user, queryStatus: 'resolved' });
  const setMemberInfo = (memberInfo) => {
    if (!authData.memberInfo || JSON.stringify(authData.memberInfo) !== JSON.stringify(memberInfo))
      setAuthData({ ...authData, memberInfo: memberInfo });
  };
  const isResolved = authData.queryStatus === 'resolved';

  //#region Firebase methods
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        // await fetchPost(env.apiBase + '/api/profile/UpdateUserProfile', {
        //   uid: response.user.uid,
        //   email: response.user.email,
        //   displayName: response.user.displayName,
        //   emailVerified: response.user.emailVerified,
        // });

        let _result = response.user;
        await fetchPost(env.apiBase + '/api/profile/getuserprofilev2', {
          uid: response.user.uid
        }).then((result) => {
          _result = result;
        })

        setUser(response.user);
        return _result;

      });
  };

  const signup = (email, password, name, phone, companyName, branchSiteName) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        await fetchPost(env.apiBase + '/api/profile/UpdateUserProfile', {
          uid: response.user.uid,
          email: response.user.email,
          displayName: response.user.displayName,
          emailVerified: response.user.emailVerified,
          name : name,
          phone : phone,
          companyName : companyName,
          branchSiteName : branchSiteName
        });
        setUser(response.user);
        return response.user;
      });
  };

  const signupForWholeSale = (value) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(value.email, value.password)
      .then(async (response) => {
        
        await fetchPost(env.apiBase + '/api/wholesale/updateuserprofile', {
          
          uid: response.user.uid,
          email: response.user.email,
          //displayName: response.user.displayName,
          emailVerified: response.user.emailVerified,
          
          firstName : value.firstName,
          lastName : value.lastName,
          businessPhone : value.businessPhone,
          mobilePhone : value.mobilePhone,
          businessName : value.businessName,

          abn : value.abn,
          acn : value.acn,

          businessCategory : value.businessCategory,
          estimatedSpend : value.estimatedSpend,

          billingInfo : value.billingInfo,
          shippingInfo : value.shippingInfo,
          useSameAddress : value.useSameAddress
          
        });

        setUser(response.user);
        return {firstName : value.firstName};
        
      });
  };

  // const signupForWholeSale = async (value) => {
    
    
  //   console.log("value ----- ", value);
    
  //   await fetchPost(env.apiBase + '/api/wholesale/updateuserprofile', {
          
  //     uid: '',
  //     email: '',
  //     //displayName: response.user.displayName,
  //     emailVerified: '',
      
  //     firstName : value.firstName,
  //     lastName : value.lastName,
  //     businessPhone : value.businessPhone,
  //     mobilePhone : value.mobilePhone,
  //     businessName : value.businessName,

  //     abn : value.abn,
  //     acn : value.acn,

  //     businessCategory : value.businessCategory,
  //     estimatedSpend : value.estimatedSpend,

  //     billingInfo : value.billingInfo,
  //     shippingInfo : value.shippingInfo,

  //     useSameAddress : value.useSameAddress
      
  //   });

  //   return "test";
  // } 

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(async () => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };
  //#endregion

  const currentFirebaseUser = async () => await getFirebaseUser();
  const loggedIn = async () => {
    if (user) return user;
    else {
      const user = await getFirebaseUser();
      return user;
    } 
  };

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      //console.log('onAuthStateChanged', user)
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // Return the user object and auth methods
  return {
    authData,
    user,
    isResolved,
    signin,
    signup,
    signupForWholeSale,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    // status
    loggedIn,
    currentFirebaseUser,
    // set
    setMemberInfo,
  };
}
